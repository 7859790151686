import {Component, effect, inject} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {companyConfigStore} from "../../../core/stores/company-config-store";

@Component({
  selector: 'calimatic-branding',
  standalone: true,
  imports: [],
  template: ''
})
export class BrandingComponent {
  configStore = inject(companyConfigStore);

  constructor(private titleService: Title) {
    effect(() => {
      this.loadComponentData();
    });
  }

  private loadComponentData() {
    if (!this.configStore.isLoading()) {
      let configs = this.configStore.configs();
      this.titleService.setTitle(configs.brandingTitle);
      this.updateFavicon(configs.brandingFavicon);
    }
  }

  private updateFavicon(href: string) {
    const faviconElm = document.getElementById('app--favicon--link') as HTMLLinkElement;
    if (faviconElm && href?.length) {
      faviconElm.href = href;
    }
  }
}
